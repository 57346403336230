import { FC } from "react";

import Header from "./components/Header/index";
import Content from "./components/Content";
import ContentContainer from "./components/ContentContainer";
import Footer from "./components/Footer";
import styled from "styled-components";
import Meta from "./components/Meta";
import { AnchorProvider } from "./context/AnchorProvider";

const Container = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
  min-height: 100vh;
`;

const App: FC = () => {
  return (
    <AnchorProvider>
      <Meta />
      <Container>
        <Header />
        <ContentContainer>
          <Content />
        </ContentContainer>
        <Footer />
      </Container>
    </AnchorProvider>
  );
};

export default App;
