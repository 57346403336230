import { FC, useMemo } from "react";

import {
  Container,
  Inner,
  LinkItem,
  LinksContainer,
  Logo,
  MenuToggle,
  ShowOnLarge,
  ShowOnSmall,
  IconContainer,
  FixedLinksContainer,
} from "./styles";

import CloseIcon from "../icons/CloseIcon";
import HamburgerMenuIcon from "../icons/HamburgerMenuIcon";
import useHeader from "./useHeader";
import { anchor } from "../../context/AnchorProvider";

const Header: FC = () => {
  const { activeAnchor, handleMenuToggle, isMenuOpen, handleOnClick } = useHeader();

  const links = useMemo(
    () => (
      <LinksContainer>
        <LinkItem isActive={activeAnchor === anchor.MENU}>
          <a href={`#${anchor.MENU}`} onClick={handleOnClick}>
            Menu
          </a>
        </LinkItem>
        <LinkItem isActive={activeAnchor === anchor.ORDER}>
          <a href={`#${anchor.ORDER}`} onClick={handleOnClick}>
            Order
          </a>
        </LinkItem>
        <LinkItem isActive={activeAnchor === anchor.ABOUT_US} href={`#${anchor.ABOUT_US}`} onClick={handleOnClick}>
          <a href={`#${anchor.ABOUT_US}`}>About us</a>
        </LinkItem>
        <LinkItem isActive={activeAnchor === anchor.ALLERGEN}>
          <a href="static/docs/brand_allergens.pdf" target="__blank" onClick={handleOnClick}>
            Allergen
          </a>
        </LinkItem>
        {/* <LinkItem isActive={activeAnchor === anchor.NUTRIENTS}>
          <a href={`#${anchor.NUTRIENTS}`} onClick={() => handleOnClick(anchor.NUTRIENTS)}>
            Nutrients
          </a>
        </LinkItem> */}
      </LinksContainer>
    ),
    [activeAnchor, handleOnClick]
  );

  return (
    <>
      <Container>
        <Inner>
          <Logo src="/static/images/brandLogo.jpg" />

          <ShowOnLarge>{links}</ShowOnLarge>

          <ShowOnSmall>
            <MenuToggle onClick={handleMenuToggle}>
              <IconContainer>{isMenuOpen ? <CloseIcon /> : <HamburgerMenuIcon />}</IconContainer>
            </MenuToggle>
          </ShowOnSmall>
        </Inner>
      </Container>

      {isMenuOpen ? <FixedLinksContainer>{links}</FixedLinksContainer> : null}
    </>
  );
};

export default Header;
