const defaultTheme: {
  breakpoints: {
    md: string;
    xs: string;
    sm: string;
    lg: string;
    xl: string;
    xxl: string;
    navigationBreakpoint: string;
    navigationBreakpointNum: number;
  };
  colors: {
    black: string;
  };
} = {
  breakpoints: {
    xs: "400px",
    sm: "600px",
    md: "960px",
    lg: "1280px",
    xl: "1440px",
    xxl: "1540px",
    navigationBreakpoint: "760px",
    navigationBreakpointNum: 760,
  },
  colors: {
    black: "rgba(29, 38, 47, 1)",
  },
};

const theme = {
  ...defaultTheme,
};

export default theme;
