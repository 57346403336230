import styled from "styled-components";
import brandConfig from "../../../../brandConfig";
import theme from "../../../../brandConfig/theme";

export const Wrapper = styled.div`
  padding: 0 16px;

  @media only screen and (min-width: ${theme.breakpoints.sm}) {
    max-width: 1410px;
    margin: 40px auto 0;
  }
`;

export const Container = styled.div`
  background-image: url("/static/images/brandBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 32px;
  overflow: hidden;
  display: block;
  justify-content: space-evenly;

  @media only screen and (min-width: ${theme.breakpoints.sm}) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ImageContainer = styled.div``;

export const StyledImage = styled.img`
  width: 100%;
`;

export const TextContainer = styled.div`
  position: relative;
  padding: 0 16px 0 32px;
  margin: 12px 0;

  @media only screen and (min-width: ${theme.breakpoints.sm}) {
    padding: 0 16px 0 80px;
    margin: 28px 0;
  }
`;

export const VerticalLine = styled.div`
  border-radius: 32px;
  width: 8px;
  position: absolute;
  z-index: 10;
  top: 0px;
  bottom: 12px;
  left: 12px;
  background-color: ${brandConfig.colors.main};
  @media only screen and (min-width: ${theme.breakpoints.sm}) {
    left: 52px;
    top: 0px;
    bottom: 0;
  }
`;

export const StyledParagraph = styled.p`
  font-size: 16px;
  margin-top: 0;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "DM Sans";
  color: ${brandConfig.colors.darkGrey};
  margin-bottom: 12px;

  @media only screen and (min-width: ${theme.breakpoints.sm}) {
    font-size: 20px;
  }
`;

export const StyledTitle = styled.h1`
  font-size: 32px;
  font-weight: 700;
  font-family: "DM Sans";
  color: ${brandConfig.colors.darkGrey};
  margin-bottom: 16px;
  margin-top: 0;

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    font-size: 55px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;

  @media only screen and (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
    padding-bottom: 0;
    flex-wrap: wrap;
  }

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    width: max-content;
  }
`;

export const StyledButton = styled.a<{ isPrimary?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  font-family: "DM Sans";
  background-color: ${({ isPrimary }) =>
    isPrimary ? brandConfig.colors.primaryButton.main : brandConfig.colors.primaryContainedButton.main};
  border-radius: 16px;
  color: ${({ isPrimary }) => (isPrimary ? brandConfig.colors.white : brandConfig.colors.darkGrey)};
  border: ${({ isPrimary }) =>
    isPrimary ? "none" : `1px solid ${brandConfig.colors.primaryContainedButton.borderColor}`};
  padding: 12px 24px;
  text-decoration: none;
  flex-wrap: nowrap;

  span {
    margin-right: 8px;
    white-space: nowrap;
  }

  &:hover {
    border-color: ${({ isPrimary }) =>
      isPrimary ? brandConfig.colors.primaryButton.hover : brandConfig.colors.primaryContainedButton.hover};

    color: ${({ isPrimary }) =>
      isPrimary ? brandConfig.colors.primaryButton.color : brandConfig.colors.primaryContainedButton.colorHover};

    background-color: ${({ isPrimary }) =>
      isPrimary ? brandConfig.colors.primaryButton.hover : brandConfig.colors.primaryContainedButton.hover};
  }

  /* desktop */
  @media only screen and (min-width: ${theme.breakpoints.sm}) {
    margin-bottom: 0px;
  }
`;
