import type { FC } from "react";

const CloseIcon: FC = (props) => (
  <svg fill="currentColor" viewBox="0 0 32 32" {...props}>
    <path
      d="M30 7C30 7.26522 29.8946 7.51957 29.7071 7.70711C29.5196 7.89464 29.2652 8 29 8H3C2.73478 8 2.48043 7.89464 2.29289 7.70711C2.10536 7.51957 2 7.26522 2 7C2 6.73478 2.10536 6.48043 2.29289 6.29289C2.48043 6.10536 2.73478 6 3 6H29C29.2652 6 29.5196 6.10536 29.7071 6.29289C29.8946 6.48043 30 6.73478 30 7ZM25 15H3C2.73478 15 2.48043 15.1054 2.29289 15.2929C2.10536 15.4804 2 15.7348 2 16C2 16.2652 2.10536 16.5196 2.29289 16.7071C2.48043 16.8946 2.73478 17 3 17H25C25.2652 17 25.5196 16.8946 25.7071 16.7071C25.8946 16.5196 26 16.2652 26 16C26 15.7348 25.8946 15.4804 25.7071 15.2929C25.5196 15.1054 25.2652 15 25 15ZM16 24H3C2.73478 24 2.48043 24.1054 2.29289 24.2929C2.10536 24.4804 2 24.7348 2 25C2 25.2652 2.10536 25.5196 2.29289 25.7071C2.48043 25.8946 2.73478 26 3 26H16C16.2652 26 16.5196 25.8946 16.7071 25.7071C16.8946 25.5196 17 25.2652 17 25C17 24.7348 16.8946 24.4804 16.7071 24.2929C16.5196 24.1054 16.2652 24 16 24Z"
      fill="#1D262F"
    />
  </svg>
);

export default CloseIcon;
