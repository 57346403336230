import { anchor } from "../../../../context/AnchorProvider";
import Anchor from "../../../Anchor";
import {
  CardContainer,
  CardImage,
  CardItem,
  StyledSubtitle,
  StyledTitle,
  BrandMascot,
  CardWrapper,
  TextWrapper,
} from "./DeliveryPartners.styles";

const DeliveryPartners = () => {
  return (
    <>
      <Anchor id={anchor.ORDER} />
      <TextWrapper>
        <StyledTitle>Our Delivery Partners</StyledTitle>
        <StyledSubtitle>Elevate your dining experience effortlessly with our seamless delivery options</StyledSubtitle>
      </TextWrapper>
      <CardWrapper>
        <CardContainer>
          <CardItem>
            <CardImage src={"/static/images/partnerDoordash.png"} alt="Doordash" />
          </CardItem>
          <CardItem>
            <CardImage src={"/static/images/partnerGrubhub.png"} alt="Grubhub" />
          </CardItem>
          <CardItem>
            <CardImage src={"/static/images/partnerUbereats.png"} alt="Uber Eats" />
          </CardItem>
          <CardItem>
            <CardImage src={"/static/images/partnerZenDelivery.png"} alt="ZenDelivery" />
          </CardItem>
          <BrandMascot src={"/static/images/brandMascot.png"} />
        </CardContainer>
      </CardWrapper>
    </>
  );
};

export default DeliveryPartners;
