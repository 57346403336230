import styled, { css } from "styled-components";

import brandConfig from "../../brandConfig";
import theme from "../../brandConfig/theme";

export const Container = styled.div`
  width: 100%;
  box-shadow: 0px 8px 40px 0px rgba(172, 163, 109, 0.12);
  height: 100px;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1000;

  @media only screen and (max-width: ${theme.breakpoints.lg}) {
    height: 72px;
  }
`;

export const Inner = styled.div`
  max-width: 1410px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  padding: 0 16px;
  display: block;

  @media only screen and (max-width: ${theme.breakpoints.lg}) {
    display: flex;
    align-items: center;
  }
`;

export const Logo = styled.img`
  height: 84px;
  width: 272px;
  padding: 8px 8px 8px 0;
  object-fit: contain;
  object-position: center left;
  position: absolute;
  left: 16px;
  top: 0;

  @media only screen and (max-width: ${theme.breakpoints.lg}) {
    position: relative;
    height: 60px;
    width: 193px;
    left: 0px;
  }
`;

export const LinksContainer = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media only screen and (max-width: ${theme.breakpoints.lg}) {
    flex: 1;
  }

  @media only screen and (max-width: ${theme.breakpoints.navigationBreakpoint}) {
    position: fixed;
    justify-content: center;
    height: min-content;
    flex-direction: column;
    align-items: baseline;
  }
`;

export const FixedLinksContainer = styled.div`
  position: fixed;
  z-index: 100;
  top: 72px;
  background: white;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const LinkItem = styled.li<{ isActive?: boolean }>`
  margin: 0 12px;
  padding: 0 12px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: ${theme.colors.black};
    text-decoration: none;
  }

  ${({ isActive }) =>
    isActive
      ? css`
          border-bottom: 6px solid ${brandConfig.colors.main};
          padding-top: 6px;
          height: calc(100% - 12px);
        `
      : ""}

  @media only screen and (max-width: ${theme.breakpoints.navigationBreakpoint}) {
    border-bottom: 0;
    margin: 24px 8px;
    font-weight: 700;

    ${({ isActive }) =>
      isActive
        ? css`
            border-bottom: 6px solid ${brandConfig.colors.main};
            height: calc(100% - 12px);
            margin: 18px 8px 12px;
            padding-bottom: 6px;
          `
        : ""}
  }
`;

export const ShowOnLarge = styled.div`
  @media only screen and (max-width: ${theme.breakpoints.navigationBreakpoint}) {
    display: none;
  }

  height: 100%;
  display: inline;
  justify-content: center;
  width: 100%;
`;

export const ShowOnSmall = styled.div`
  @media only screen and (max-width: ${theme.breakpoints.navigationBreakpoint}) {
    display: flex;
    width: 100%;
    flex: 1;
    justify-content: flex-end;
  }

  display: none;
`;

export const MenuToggle = styled.div`
  background: ${brandConfig.colors.lightMain};
  height: 72px;
  width: 72px;
  margin-right: -16px;
  position: relative;
`;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  padding: 16px;
`;
