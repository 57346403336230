import { useCallback, useContext, useEffect, useState } from "react";
import { AnchorContext, anchor } from "../../context/AnchorProvider";

const useHeader = (): {
  handleMenuToggle: () => void;
  isMenuOpen: boolean;
  handleOnClick: () => void;
  activeAnchor: anchor;
} => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const { activeAnchor } = useContext(AnchorContext);

  const handleMenuToggle = useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isMenuOpen]);

  const handleWindowSizeChange = useCallback(() => {
    document.body.style.overflowY = "auto";
    setIsMenuOpen(false);
  }, []);

  useEffect(() => {
    const windowGlobal = typeof window !== "undefined" ? window : false;
    if (windowGlobal) {
      windowGlobal.addEventListener("resize", handleWindowSizeChange);

      return () => windowGlobal.removeEventListener("resize", handleWindowSizeChange);
    }
  }, [handleWindowSizeChange]);

  const handleOnClick = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  return {
    handleMenuToggle,
    isMenuOpen,
    handleOnClick,
    activeAnchor,
  };
};

export default useHeader;
