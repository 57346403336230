import { FC } from "react";

import { Container, Logo, LinksContainer, Link, Copyright } from "./styles";
import brandConfig from "../../brandConfig";

const date = new Date();

const currentYear = date.getFullYear();

const Footer: FC = () => {
  return (
    <Container>
      <Logo src="/static/images/brandLogo.jpg" />
      <LinksContainer>
        <Link href={`/static/docs/${brandConfig.constants.termsAndConditionsPdf}`} target="__blank">
          Terms and Conditions
        </Link>
        <Link href={`/static/docs/${brandConfig.constants.privacyPolicyPdf}`} target="__blank">
          Privacy Policy
        </Link>
        <Copyright>
          {currentYear} {brandConfig.brandData.title}. All rights reserved.
        </Copyright>
      </LinksContainer>
    </Container>
  );
};

export default Footer;
