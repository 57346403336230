import { FC } from "react";

import {
  Container,
  InnerContainer,
  Image,
  LeftContainer,
  RightContainer,
  Line,
  Subtitle,
  Title,
  Text,
  ButtonContainer,
  StyledButton,
} from "./styles";

import Anchor from "../../../Anchor";
import ArrowRight from "../../../icons/ArrowRight";

import brandConfig from "../../../../brandConfig";
import { anchor } from "../../../../context/AnchorProvider";

const AboutUs: FC = () => {
  return (
    <>
      <Anchor id={anchor.ABOUT_US}></Anchor>
      <Container>
        <InnerContainer>
          <LeftContainer>
            <Image src="static/images/brandAboutUsImage.png" />
          </LeftContainer>
          <Line />
          <RightContainer>
            {brandConfig.content.aboutUs.subtitle ? <Subtitle>{brandConfig.content.aboutUs.subtitle}</Subtitle> : null}
            <Title>{brandConfig.content.aboutUs.title}</Title>
            <Text>
              {brandConfig.content.aboutUs.text.split("\n").map((text) => (
                <p>{text}</p>
              ))}
            </Text>
            <ButtonContainer>
              <StyledButton href="static/docs/brand_allergens.pdf" target="__blank">
                <span>Allergen Information</span>
                <ArrowRight color={brandConfig.colors.secondaryButton.color}></ArrowRight>
              </StyledButton>
              {/* <StyledButton href="/" target="__blank">
                <span>Nutritional Information</span>
                <ArrowRight color={brandConfig.colors.secondaryButton.color}></ArrowRight>
              </StyledButton> */}
            </ButtonContainer>
          </RightContainer>
        </InnerContainer>
      </Container>
    </>
  );
};

export default AboutUs;
