import brandConfig from "../../brandConfig";

import { Helmet } from "react-helmet";

const Meta = () => {
  return (
    <Helmet>
      <title>{brandConfig.content.meta.title}</title>
      <meta property="og:description" content={brandConfig.content.meta.description} key="og:description" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charSet="utf-8" />
    </Helmet>
  );
};

export default Meta;
