import AboutUs from "./components/AboutUs";
import DeliveryPartners from "./components/DeliveryPartners";
import Hero from "./components/Hero";
import { Wrapper } from "./Content.styles";

const Content = () => {
  return (
    <div>
      <Hero />
      <DeliveryPartners />
      <AboutUs />
    </div>
  );
};

export default Content;
