import type { FC } from "react";

const ArrowRight: FC<{ color: string }> = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color={color}>
    <g id="Icons">
      <path
        id="Vector"
        d="M20.7823 11.5041L20.7819 11.5037L15.3325 6.05429C15.0638 5.74742 14.5974 5.71323 14.2868 5.97929C13.9742 6.24706 13.9377 6.71766 14.2055 7.03036L14.2056 7.03041C14.2286 7.05726 14.2536 7.08263 14.2805 7.10619L18.4562 11.2869H3.54543C3.13375 11.2869 2.8 11.6207 2.8 12.0324C2.8 12.4441 3.13377 12.7778 3.54543 12.7778H18.4558L14.2806 16.9531C13.9737 17.2217 13.9395 17.6881 14.2055 17.9987C14.4734 18.3114 14.9439 18.3479 15.2566 18.08L15.2567 18.08C15.2835 18.0569 15.3089 18.0319 15.3325 18.005L20.7819 12.5556L20.7823 12.5552C21.0713 12.2645 21.0713 11.7949 20.7823 11.5041Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.4"
      />
    </g>
  </svg>
);

export default ArrowRight;
