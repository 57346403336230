import styled from "styled-components";
import theme from "../../brandConfig/theme";

export const Container = styled.div`
  max-width: 1410px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  width: calc(100% - 32px);

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Logo = styled.img`
  height: 120px;
  width: 389px;
  max-width: 100%;
  padding: 8px 8px 8px 0;
  object-fit: contain;
  object-position: center left;
  left: 16px;
  top: 0;

  @media only screen and (max-width: ${theme.breakpoints.lg}) {
    height: 102px;
    width: 328px;
    left: 0px;
    padding: 0;
    object-position: center center;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
  }

  @media only screen and (max-width: ${theme.breakpoints.lg}) {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`;

export const Link = styled.a`
  font-size: 16px;
  padding: 20px;
  cursor: pointer;
  text-decoration: underline;
  color: ${theme.colors.black};

  @media only screen and (max-width: ${theme.breakpoints.lg}) {
    padding: 12px;
  }
`;

export const Copyright = styled.div`
  font-size: 16px;
  padding: 20px;

  @media only screen and (max-width: ${theme.breakpoints.lg}) {
    padding: 12px;
  }
`;
