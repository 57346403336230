import styled from "styled-components";
import brandConfig from "../../../../brandConfig";
import theme from "../../../../brandConfig/theme";

export const Container = styled.div`
  width: 100vw;
  background: #f2f5f7;
  margin-bottom: 40px;
  @media only screen and (max-width: ${theme.breakpoints.md}) {
    margin-bottom: 16px;
  }
`;

export const InnerContainer = styled.div`
  flex-direction: row;
  display: flex;
  padding: 80px 0;

  @media only screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    margin: 0 auto;
    padding: 0;
    display: flex;
  }
`;

export const Image = styled.img`
  height: auto;
  width: 100%;
  border-radius: 0 32px 32px 0;
  @media only screen and (max-width: ${theme.breakpoints.md}) {
    border-radius: 0;
  }
`;

export const LeftContainer = styled.div`
  width: calc(50% - 78px);
  margin-right: 32px;
  display: flex;
  align-items: center;
  margin-bottom: 0;

  @media only screen and (max-width: ${theme.breakpoints.md}) {
    width: 100%;
    margin-bottom: 16px;
    padding: 0;
  }
`;

export const Line = styled.div`
  width: 6px;
  background: ${brandConfig.colors.main};
  border-radius: 4px;
  display: flex;
`;

export const RightContainer = styled.div`
  max-width: 705px;
  width: calc(50%);
  padding: 0 16px;

  @media only screen and (max-width: ${theme.breakpoints.md}) {
    width: calc(100% - 32px);
    max-width: unset;
    padding: 16px;
  }
`;

export const Subtitle = styled.h3`
  font-size: 20px;
  text-transform: uppercase;
  margin: 0;
  @media only screen and (max-width: ${theme.breakpoints.md}) {
    font-size: 16px;
  }
`;

export const Title = styled.h2`
  font-size: 55px;
  margin: 0;
  @media only screen and (max-width: ${theme.breakpoints.md}) {
    font-size: 32px;
    margin-bottom: 12px;
  }
`;

export const Text = styled.p`
  font-size: 22px;
  margin: 0;
  margin-bottom: 24px;

  @media only screen and (max-width: ${theme.breakpoints.md}) {
    margin: 0;
    margin-bottom: 16px;
    font-size: 18px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
  margin-bottom: 16px;
`;

export const StyledButton = styled.a`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: "DM Sans";
  background: ${brandConfig.colors.secondaryButton.main};
  border-radius: 16px;
  padding: 12px 24px;
  text-decoration: none;
  border: none;
  color: ${brandConfig.colors.secondaryButton.color};

  &:hover {
    background: ${brandConfig.colors.secondaryButton.hover};
  }

  span {
    margin-right: 8px;
  }
`;
