import styled from "styled-components";
import theme from "../../brandConfig/theme";

export const Container = styled.div`
  /* max-width: 1410px; */
  margin: 100px auto 0 auto;
  /* padding: 40px 16px; */
  flex: 1;
  width: 100%;

  @media only screen and (max-width: ${theme.breakpoints.lg}) {
    /* padding: 40px 16px; */
    /* margin: 72px 16px; */
  }

  @media only screen and (max-width: ${theme.breakpoints.xs}) {
    /* padding: 16px; */
  }
`;
