import brandConfig from "../../../../brandConfig";
// import theme from "../../../../brandConfig/theme";
import { anchor } from "../../../../context/AnchorProvider";
import Anchor from "../../../Anchor";
import ArrowRight from "../../../icons/ArrowRight";
import {
  ButtonContainer,
  Container,
  ImageContainer,
  StyledButton,
  StyledImage,
  StyledParagraph,
  StyledTitle,
  TextContainer,
  VerticalLine,
  Wrapper,
} from "./Hero.styles";

const Hero = () => {
  return (
    <Wrapper>
      <Anchor id={anchor.MENU} />
      <Container>
        <ImageContainer>
          <StyledImage src="/static/images/brandHero.png" />
        </ImageContainer>
        <TextContainer>
          <VerticalLine />
          <StyledParagraph>{brandConfig.content.hero.subtitle}</StyledParagraph>
          <StyledTitle>{brandConfig.content.hero.title}</StyledTitle>
          <ButtonContainer>
            <StyledButton isPrimary href={`#${anchor.ORDER}`}>
              <span>{brandConfig.content.hero.orderNowText}</span>
              <ArrowRight color="inherit" />
            </StyledButton>
            {brandConfig.constants.menuAtAGlancePdf ? (
              <StyledButton href={`/static/docs/${brandConfig.constants.menuAtAGlancePdf}`} target="__blank">
                <span>{brandConfig.content.hero.viewMenuText}</span>
                <ArrowRight color="inherit" />
              </StyledButton>
            ) : null}
          </ButtonContainer>
        </TextContainer>
      </Container>
    </Wrapper>
  );
};

export default Hero;
