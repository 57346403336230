import { FC, useContext } from "react";

import { Waypoint } from "react-waypoint";

import styled from "styled-components";
import theme from "../../brandConfig/theme";
import { AnchorContext, anchor } from "../../context/AnchorProvider";

const Container = styled.div`
  visibility: hidden;
  height: 0;
  width: 0;
  position: relative;
`;

const Span = styled.span`
  position: absolute;
  top: -72px;

  @media only screen and (min-width: ${theme.breakpoints.lg}) {
    top: -100px;
  }
`;

const Anchor: FC<{ id: anchor }> = ({ id }) => {
  const { setActiveAnchor } = useContext(AnchorContext);

  return (
    <Container>
      <Waypoint bottomOffset={"90%"} onEnter={() => setActiveAnchor(id)}>
        <Span id={id}></Span>
      </Waypoint>
    </Container>
  );
};

export default Anchor;
