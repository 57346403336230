import { createContext, useMemo, useState } from "react";

import type { ReactNode, FC, Dispatch, SetStateAction } from "react";

export enum anchor {
  MENU = "menu",
  ORDER = "order",
  ABOUT_US = "about-us",
  ALLERGEN = "allergen",
  NUTRIENTS = "nutrients",
}

type ContextValue = {
  activeAnchor: anchor;
  setActiveAnchor: Dispatch<SetStateAction<anchor>>;
};

export const AnchorContext = createContext<ContextValue>({
  activeAnchor: anchor.MENU,
  setActiveAnchor: () => undefined,
});

export const AnchorProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [activeAnchor, setActiveAnchor] = useState<anchor>(anchor.MENU);

  const memoedValue = useMemo(
    () => ({
      activeAnchor,
      setActiveAnchor,
    }),
    [activeAnchor, setActiveAnchor]
  );

  const content = useMemo(
    () => <AnchorContext.Provider value={memoedValue}>{children}</AnchorContext.Provider>,
    [children, memoedValue]
  );

  return content;
};
