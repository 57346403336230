import styled from "styled-components";
import theme from "../../../../brandConfig/theme";

export const CardWrapper = styled.div`
  padding: 0;

  @media only screen and (min-width: ${theme.breakpoints.sm}) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  padding: 0 16px;

  @media only screen and (min-width: ${theme.breakpoints.sm}) {
    padding: 0px;
  }
`;

export const StyledTitle = styled.h1`
  font-size: 32px;
  font-family: "DM Sans";
  font-weight: 700;
  margin-bottom: 8px;
  text-align: center;

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    font-size: 44px;
  }
`;

export const StyledSubtitle = styled.p`
  font-size: 22px;
  font-family: "DM Sans";
  font-weight: 400;
  margin-bottom: 16px;
  text-align: center;

  @media only screen and (min-width: ${theme.breakpoints.sm}) {
    margin-bottom: 32px;
  }
`;

export const CardContainer = styled.div`
  position: relative;
  overflow: visible;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 16px 24px 40px 24px;
  margin-bottom: 16px;
  row-gap: 16px;
  column-gap: 16px;

  @media only screen and (min-width: ${theme.breakpoints.sm}) {
    row-gap: 24px;
    column-gap: 24px;
  }
  @media only screen and (min-width: ${theme.breakpoints.md}) {
    margin-bottom: 50px;
  }
`;

export const CardItem = styled.div`
  flex: 0 1 auto;
  border-radius: 16px;
  box-shadow: 1px 16px 40px 0px rgba(86, 104, 117, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(90% / 2);
  min-height: 157px;

  @media only screen and (min-width: ${theme.breakpoints.sm}) {
    width: auto;
    flex: 1;
  }
`;

export const CardImage = styled.img`
  width: 100%;
  padding: 0 10px;
  overflow: hidden;
`;

export const BrandMascot = styled.img`
  display: none;

  @media only screen and (min-width: ${theme.breakpoints.sm}) {
    display: none;
  }

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    display: block;
    height: 126px;
    width: auto;
    position: absolute;
    z-index: 10;
    bottom: -26px;
    right: 0;
  }

  @media only screen and (min-width: ${theme.breakpoints.lg}) {
    width: auto;
    position: absolute;
    z-index: 10;
    bottom: 0;
    bottom: -36px;
    right: 0;
  }
  @media only screen and (min-width: ${theme.breakpoints.xl}) {
    height: 153px;
    right: 0px;
    bottom: -66px;
  }

  @media only screen and (min-width: ${theme.breakpoints.xxl}) {
    display: block;
    right: -100px;
  }
`;

export const Container = styled.div`
  position: relative;
  max-width: 1410px;
  padding: 16px;

  @media only screen and (max-width: ${theme.breakpoints.lg}) {
    padding: 16px;
    margin: 16px;
    right: -100px;
  }

  @media only screen and (max-width: ${theme.breakpoints.xs}) {
    padding: 16px;
    margin: 0;
  }

  @media only screen and (min-width: ${theme.breakpoints.xxl}) {
    display: block;
    right: -100px;
  }
`;
