import { BrandConfig, BrandData, Colors, Constants, Content } from "../../types";

const brandData: BrandData = {
  id: 8,
  title: "Big Edna's Breakfast",
};

const colors: Colors = {
  lightMain: "rgba(246, 188, 27, 0.25)",
  main: "#7FCCEE",
  white: "#fff",
  darkGrey: "#1d262f",
  primaryButton: {
    main: "#F45C55",
    hover: "#D7514B",
    color: "white",
  },
  primaryContainedButton: {
    main: "white",
    hover: "#F45C55",
    color: "rgba(29, 38, 47, 1)",
    colorHover: "white",
    borderColor: "#F45C55",
  },
  secondaryButton: {
    main: "#F45C55",
    hover: "#D7514B",
    color: "white",
  },
};

const content: Content = {
  aboutUs: {
    subtitle: "",
    text: "Big Edna knows how to take care of breakfast. Believe us y’all, you will not leave hungry. At Big Edna’s, we are known for our stacked Texas toast breakfast sandwiches and barbecue breakfast tacos. Try them both, and thank us later. We rustle up some mighty fine scrambled eggs too - in fact that’s the only way Big Edna serves them. \nAlso on the do not miss list our Southern Love special. We take Texas toast, topped with crispy fried chicken, scrambled eggs and finished with jalapeno sausage white gravy. Shut the front door, it’s good. Have a sweet tooth, so does Big Edna. Try our Crispy Texas Cinnamon Toast. We deep fry our signature Texas toast and toss it in cinnamon and sugar, then serve it with maple syrup and butter. Finally, would we be able to claim our southern roots if we didn’t serve the best tots & gravy this side of the Mason-Dixon Line? We take crispy potato tots, top them with scrambled eggs, smoked brisket and black pepper gravy. You’re welcome and bless your heart.",
    title: "Big Edna’s Breakfast - let’s get toasty",
  },
  hero: {
    title: "Big Edna’s Breakfast",
    subtitle: "Let’s get toasty",
    orderNowText: "Order Now",
    viewMenuText: "View Menu",
  },
  meta: {
    title: "Big Edna's Breakfast",
    description:
      "Welcome to Big Edna’s Breakfast, where the aroma of freshly brewed coffee and the sizzle of skillet creations greet you with warmth. Our story is one woven with passion for crafting memorable breakfast experiences. From fluffy pancakes to hearty omelets and crispy bacon, we believe in the power of a good morning meal to kickstart your day. Established with a commitment to quality ingredients, friendly service, and a cozy ambiance, we invite you to join us for a breakfast experience that's not just about food but about starting your day on a delightful note. At Big Betsy's, mornings are our canvas, and your satisfaction is our masterpiece.",
    image: "",
    url: "",
  },
};

const constants: Constants = {
  menuAtAGlancePdf: "brand_Menu_At_Glance.pdf",
  privacyPolicyPdf: "BigEdna_Privacy_policy.pdf",
  termsAndConditionsPdf: "BigEdna_T&C.pdf",
};

const brandConfig: BrandConfig = {
  brandData,
  constants,
  content,
  colors,
};

export default brandConfig;
