import type { FC } from "react";

const CloseIcon: FC = (props) => (
  <svg fill="currentColor" viewBox="0 0 32 32" {...props}>
    <g clip-path="url(#clip0_189_2814)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.3339 9.08588L17.752 15.6677L24.3339 22.2496C24.5662 22.4819 24.6967 22.797 24.6967 23.1256C24.6967 23.4542 24.5662 23.7693 24.3339 24.0016C24.1015 24.2339 23.7864 24.3645 23.4579 24.3645C23.1293 24.3645 22.8142 24.2339 22.5819 24.0016L16 17.4198L9.41814 24.0016C9.18581 24.2339 8.8707 24.3645 8.54214 24.3645C8.21357 24.3645 7.89846 24.2339 7.66613 24.0016C7.4338 23.7693 7.30328 23.4542 7.30328 23.1256C7.30328 22.797 7.4338 22.4819 7.66613 22.2496L14.248 15.6677L7.66613 9.08588C7.4338 8.85355 7.30328 8.53844 7.30328 8.20988C7.30328 7.88131 7.4338 7.56621 7.66613 7.33388C7.89846 7.10155 8.21357 6.97102 8.54214 6.97102C8.8707 6.97102 9.18581 7.10155 9.41814 7.33388L16 13.9157L22.5819 7.33388C22.8142 7.10155 23.1293 6.97102 23.4579 6.97102C23.7864 6.97102 24.1015 7.10155 24.3339 7.33388C24.5662 7.56621 24.6967 7.88131 24.6967 8.20988C24.6967 8.53844 24.5662 8.85355 24.3339 9.08588Z"
        fill="#1D262F"
      />
    </g>
    <defs>
      <clipPath id="clip0_189_2814">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CloseIcon;
